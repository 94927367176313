import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react'
import { useCurrentUser } from '../hooks/useCurrentUser'
import type { GetServerSidePropsContext, NextPage } from 'next'
import { useRouter } from 'next/router'
import Image from '../components/Image/Image'

const Home: NextPage = () => {
  const session = useSession()
  const supabase = useSupabaseClient()
  const router = useRouter()

  return (
    <Stack gap={2}>
      <Image
        src="/placeholder.png"
        alt="placeholder"
        minH={523}
        border={'2px'}
        borderColor={'blackAlpha.900'}
        borderRadius={16}
      />
      <Stack>
        <Text fontWeight={700} textTransform="uppercase">
          Radio Remember
        </Text>
        <Heading fontWeight={'black'}>
          Create your <br></br> Memory Station
        </Heading>
      </Stack>

      <Button
        onClick={() => router.push('/onboarding')}
        rightIcon={<ChevronRightIcon w={6} h={6} />}
        colorScheme="brand"
        w="full"
      >
        Create today
      </Button>
    </Stack>
  )
}

export default Home
